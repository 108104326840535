import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { PageSeo } from "../components/PageSeo"
import Content, { HTMLContent } from "../components/Content"

const DefaultTemplate = ({ data }) => {
  const { markdownRemark: markup } = data

  const PageContent = HTMLContent || Content

  return (
    <Layout>
      <div className="container-fluid DefaultTemplate">
        <div className="row">
          <div className="col-12">
            <div className="card my-3">
              <div className="card-body shadow">
                <PageContent className="content" content={markup.html} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const PageTemplateQuery = graphql`
  query PageTemplateQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
      }
    }
  }
`
export default DefaultTemplate
export const Head = () => <PageSeo />
